
export async function apiRequest( 
  endpoint, request): Promise<any> {

  const response: any = await fetch( endpoint, {
    ...request,
  //  headers: { ...request.headers, 'Content-Type': 'application/json'}
  });

  if (response.status < 200 || response.status >= 300) {
    const error: any = new Error(response.statusText);
    error.response = response;
    error.status = response.status;
    throw error;
  }

  return response;
}


