import React from 'react';
import { styled } from '@material-ui/core/styles';

const Footer = (props) => {

  const ResponsiveFooter = styled('div')(({
    theme
  }) => ({

    [theme.breakpoints.down('md')]: {
      height: "70px",
    },
  }));
  return (
    <ResponsiveFooter />
  )
}

export default Footer;