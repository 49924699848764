


const baseURL: string  = `${process.env.REACT_APP_BASE}`
export const yelpUrl: string = `${baseURL}/api`

export const counties: Array<string>  = ['King County','NY', 'New York', 'NEW YORK', "New", "York", "NewYork", "new york", "Cook County"];

export const availableHealthAPI: Array<string>  = ['King County', 'New York', "Cook County", "Travis County"]


export const defaultMapTheme: string  = "dark";

// REDO? Make { elementType: 'geometry', stylers: [{ color: '#242f3e' }] }
// A class the new DarkElment('geometry', stylers: [{ color: '#242f3e' })
interface DarkStyles {

  featureType: string,
  elementType: string,
  stylers: Array<{}>,
}
export const darkMap: Array<DarkStyles> = [
  { featureType:"all", elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { featureType: "all", elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { featureType: "all", elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#242f3e' },{ color: '#263c3f' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#746855' },{ color: '#6b9a76' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }]
  }
]
