import { apiRequest } from '../utils/index';
import markers from './markers';
import {yelpUrl} from './constants'
import { darkMap, counties, defaultMapTheme } from './constants';
import { setHealthAPIURL } from './healthURLModels';
const mapThemes = {'dark': darkMap, 'light': null}


 // https://stackoverflow.com/questions/55464196/property-google-does-not-exist-on-type-window
class GoogleMapsApi {
  map: any;
  service: any
  place: any;
  exactLocation: any
  markers: Array<any>
  mapTheme: string;
  lat: number;
  lon: number;
clickedElement = null;
  constructor() {
    this.map = null
    this.service = null
    this.place = null
    this.exactLocation = null
    this.markers = []
    this.mapTheme = defaultMapTheme
    this.lat = null
    this.lon = null
    this.clickedElement = null;
  }

 // https://stackoverflow.com/questions/55464196/property-google-does-not-exist-on-type-window
  initiateMap(latitude = null,longitude = null,zoom=null){
    const toZoom = zoom? zoom : 14
    this.setLocation(latitude, longitude);
      const mapElement = document.getElementById('map');
    const map = new (window as any).google.maps.Map(mapElement, {
        center: this.exactLocation,
        zoom: toZoom,
        styles: mapThemes[this.mapTheme],
        fullscreenControl: false,
        
      });
      this.map = map;
  }

  setLocation(latitude = null, longitude = null){
    const lat = latitude == null ? this.place.geometry.location.lat() : latitude;
    const lon = longitude == null ? this.place.geometry.location.lng() : longitude;
    this.lat = lat;
    this.lon = lon;
    this.exactLocation = new (window as any).google.maps.LatLng(lat, lon);
  
  }

  changeMapTheme(mapTheme){
    if (this.map){
      this.mapTheme = mapTheme;
      this.map.setOptions({ styles: mapThemes[mapTheme] })
    }
    else{
      this.mapTheme = mapTheme;
    }
 }



  autoCompletePlace(inputElement, setPlace) {
    enableEnterKey(inputElement);
    const searchBox = new (window as any).google.maps.places.Autocomplete(inputElement);
    let place;

 
    searchBox.addListener('place_changed', () => {
    
      place = searchBox.getPlace();
      if (place.length === 0) {
        return;
      }
      this.place = place;

      setPlace(place);
      this.initiateMap();
    });
  }

  findPlaces(query, setPlaceData){
    const location = this.exactLocation;
    const request = {
      location, 
      radius: '5',
      query,
      fields: ['formatted_address',
      'id', 'photos', 'place_id', 'name','price_level',
        'rating', 'user_ratings_total', 'international_phone_number','utc_offset_minutes']
    };
    let service = new (window as any).google.maps.places.PlacesService(this.map);
    this.service = service;
    service.textSearch(request, callback);

    function callback(results, status) {
      let allPlaceData = [];
      for (let i = 0; i < results.length; i++) {
        allPlaceData.push(results[i]); 
      }
      setPlaceData(allPlaceData);
    }

   // searchNeightborHoodOnMapClick(this);
  //  function searchNeightborHoodOnMapClick(thisContext){
         // this.map.addListener("click", (e) => {
    //   const cb=()=>{
    //     thisContext.findPlaces(query, setPlaceData);
    //   }
    //   thisContext.initiateMap(e.latLng.lat(), e.latLng.lng(),cb);
    // });
    //}
 
  }

  getDetails = (places) => {
    const requests = []; 
    for (let index = 0; index < places.length; index++) {
      let request = {
        placeId: places[index].placeId,
        fields: ['place_id', 'url','reviews','name', 'international_phone_number', 
          'formatted_phone_number', 'url', 'website', "opening_hours" ]
      };
      requests.push(request)
    }
    let phoneNumbers = []
    const service = this.service;

    return new Promise(function (resolve, reject) {
      getPhoneToReturn(requests, 0, phoneNumbers, service)
      
        function getPhoneToReturn(requests, index, phoneNumbers, service) {
          if (index < requests.length) {
            const promise1 = new Promise(function (resolve, reject) {
              service.getDetails(requests[index], callback);
              // NEED REJECT FOR ERROR (connection / wrong data ect...)
              // Iif not found then keep resolve
              function callback(place, status) {
                if (status) {
                  resolve(place);
                }
                else {
                  console.log('statusgetPhoneToReturn', status);
                 reject()
                }
              }
            });
            promise1.then((place) => {
              phoneNumbers.push(place);    
              getPhoneToReturn(requests, index+1, phoneNumbers, service);
            }, e => {
              phoneNumbers.push({ name: "", formatted_phone_number: "", international_phone_number: "" });

              getPhoneToReturn(requests, index + 1, phoneNumbers, service);
            })
          }
          else {
            resolve(phoneNumbers)
          }
        }
    });
  }

  getMarkers = (places) => {
     return places.map(ele=> {
 
      const latitude = ele.lat
      const longitude = ele.lng;
       const myLatlng = new (window as any).google.maps.LatLng(latitude, longitude);
     const chosenMarkericon = markers[ele.marker];
      const markerOptions = {
        position: myLatlng,
        title: ele.name,
        placeId: ele.placeId,
        icon: chosenMarkericon,
      }
  
       let marker = new (window as any).google.maps.Marker(markerOptions); 
       return marker
    })

  }

  changeMarker = (marker) => {

    const latitude = marker.lat
    const longitude = marker.lng;
    const myLatlng = new (window as any).google.maps.LatLng(latitude, longitude);
    const chosenMarkericon = markers[marker.marker];
      const markerOptions = {
        position: myLatlng,
        title: marker.name,
        icon: chosenMarkericon,
      }
    let newMarker = new (window as any).google.maps.Marker(markerOptions);
      return newMarker
  }

}


export function enableEnterKey(input) {
  /* Store original event listener */
  const _addEventListener = input.addEventListener
  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener
      listener = (event) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
        if (event.key === 'Enter' && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          })
          _listener.apply(input, [e])
        }
        _listener.apply(input, [event])
      }
    }
    _addEventListener.apply(input, [type, listener])
  }

  input.addEventListener = addEventListenerWrapper
}

export const buildYelpQuery = (allPlaces, phoneNumbers, placeDetails) => {
  const { city, state, country } = placeDetails;
  const queries = { data: [] }; 
  for (let i = 0; i < allPlaces.length; i++) {

    let phoneNumbersFormated;
    if (phoneNumbers[i] != null && phoneNumbers[i].hasOwnProperty("international_phone_number")) {
      phoneNumbersFormated = phoneNumbers[i].international_phone_number
        .split('')
        .filter(letter => letter !== '-' && letter !== ' ')
        .join('');
    }
    else {
      phoneNumbersFormated = null;
    }

    queries.data.push({ placeId: allPlaces[i].placeId, phoneNumber: phoneNumbersFormated, city, state, country, name: allPlaces[i].name, address: allPlaces[i].address })
  }
  return queries;
}

export const buildHealthQuery = (places, placeData, phoneNumbers,healthLocation) => {
  healthLocation = healthLocation || "King County"
  const isShowHealth = checkMatchPlaceData(placeData);
 // const wholeURL = { url: "https://data.kingcounty.gov/resource/gkhn-e8mn.json?$where=", urlEnd: `&$order=inspection_date%20DESC` };
  // debugger;
  return getHealthAPI();

 
  function getHealthAPI(){
    const queries = [];
    for (let i = 0; i < places.length; i++) {
  
    //  NewYork(phoneNumbers[i],"phoneURL")

      const healthAPI = new setHealthAPIURL[healthLocation](places[i],phoneNumbers[i])
     
      const apiSearchValue = { health: { url: "", placeId: "" } } //{ health: { url: "", placeId: "", wholeURL: "" } };

      // if (isShowHealth) {
      apiSearchValue.health.url = healthAPI.finalURL
        //  getWithOneRequst(i, places, healthAPiQuery,wholeURL);
      // }
      apiSearchValue.health.placeId = healthAPI.place_id
      queries.push(apiSearchValue);
   }
    return queries;
  }

 // return getNames();

  function getNames() {
    const queries = [];
    for (let i = 0; i < places.length; i++) {
      let healthName = formatHealthName(places[i].name.toUpperCase());
      let address = formatAddress(places[i].address);
      let partialAddressQuery = '%20'
      let healthAPiQuery = formatUrlQuery(healthName, address, partialAddressQuery);
      const apiSearchValue = { health: { url: "", placeId: "" } } //{ health: { url: "", placeId: "", wholeURL: "" } };
      
      if (isShowHealth){
        apiSearchValue.health.url = healthAPiQuery
      //  getWithOneRequst(i, places, healthAPiQuery,wholeURL);
      }     
      apiSearchValue.health.placeId= places[i].placeId 
      queries.push(apiSearchValue);
    }
    //queries[0].health.wholeURL = wholeURL.url
    // debugger;
    return queries;
  }

  function formatHealthName(name) {
    const regex = /%20and%20/gi;
    const regex3 = /'s/gi;
    const regex4 = /'/gi;

    name = modifyAccent(name);
    let healthName = fixedEncodeURIComponent(name.toUpperCase());
    healthName = healthName.replace(regex, '%20%26%20');

    healthName = healthName.replace(regex3, '')
    healthName = healthName.replace(regex4, '')

    return healthName;
  }

  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  function modifyAccent(str){
    const regex = RegExp('/[À-ÿ]/');
    const isAccent =  regex.test(str);
 
    if (isAccent){
    str =  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
   return str
  }


  function formatAddress(initialAddress) {
    let addressArray = initialAddress.split(' ');
    addressArray = addressArray.slice(0, 2);
    let partialaddress = addressArray.join(' ');
    let address = encodeURIComponent(partialaddress).toUpperCase();
    return address;
  }

  function formatUrlQuery(healthName, address, partialAddressQuery) {
    let tempName = healthName;
    let index = tempName.lastIndexOf("%20");
    let firstIndex = tempName.indexOf("%20");
    let theArray = tempName.split('').slice(index + 3, tempName.length).join('');
    let first = tempName.split('').slice(0, firstIndex).join('');
    let t = `like%20'%25${theArray}'`;
    let firstHealth = `starts_with(name, '${first}')`;
  
    let url = `https://data.kingcounty.gov/resource/gkhn-e8mn.json?$where=(upper(address)%20like%20'%25${address}%25'%20AND%20(name%20${t}%20OR%20name%20=%20'${healthName}'%20OR%20${firstHealth}))${partialAddressQuery}&$order=inspection_date%20DESC`;
   // let urlOneRequst = `(upper(address)%20like%20'%25${address}%25'%20AND%20(name%20${t}%20OR%20name%20=%20'${healthName}'%20OR%20${firstHealth}))${partialAddressQuery}`
    return url;
  }
}

export const getYelpHealthData = (queries, setState) => {
  const queryiesForApi =  JSON.stringify(queries)
  const request = {
    method: 'POST',
 headers: {
   'Accept': 'application/json',
   'Content-Type': 'application/json'
  },
    body: queryiesForApi
  }

  Promise.resolve(
    apiRequest(yelpUrl, request)
  )
    .then((response) => {
     const jsonResponse =  response.json();
      return jsonResponse
    }).then((data) => {
      const errors = data.errors;
      // eslint-disable-next-line no-unused-vars
      for (const key in errors) {
        if (data.errors[key].length > 0){
          errors[key].forEach(element => {
            const errorMessage =  Object.entries(element)
            console.log('(data errors', errorMessage);
            console.log('(data errors', key);
          });  
        }
      }
     setState(data);
    }).catch(e=>{
      console.log('req.body.yelp', e);
    })
};

export const getYelpReviews = (yelpId) => {
  const url = yelpUrl + '/yelp-review';
  yelpId = JSON.stringify({yelpId})
  const request = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: yelpId
  }
 return Promise.resolve(
    apiRequest(url, request)
  )
    .then((response) => {
      return response.json()
    }).then((data) => {
      return data;
    }).catch(e => {
      console.log('req.body.yelpReviews', e);
    })
}


// function getWithOneRequst(i, places, healthAPiQuery,wholeURL) {
//   const isMore = i === places.length - 1 ? "" : "OR";
//   wholeURL.url += `${healthAPiQuery}${isMore}`;
//   return wholeURL;
// }

export function checkMatchPlaceData(placeData){
  const checkMatch = (element) => element === placeData.county || placeData.city;
  const isShowHealth = counties.some(checkMatch);
  return isShowHealth;
}


export let googleMapsApi = new GoogleMapsApi();