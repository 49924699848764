import { InspectionLabels, newYorkLabels, kingCountyLabels, cookCountyLabels, travisCountyLabels } from "./healthLabels";



export class NewYork {
  placeId: string
  name: string;
  recentGrade: string;
  inspectionHistory: Array<InspectionLabels>
  constructor(placeId, healthData) {
    let name: string = "";
    let recentGrade:string = "";

    if (healthData.length > 0) {
      name = healthData[0].dba
      recentGrade = healthData[0]?.grade ? healthData[0]?.grade : getNewYorkGrade(healthData[0]);
    }

    this.placeId = placeId;
    this.name = name;
    this.recentGrade = recentGrade
    this.inspectionHistory = InspectionLabels.getInspections(healthData,newYorkLabels) || [];

    function getNewYorkGrade(healthData):string{
     // of 0 to 13 is an A, 14 to 27 points is a B, and 28 or more points is a C
      let score = parseInt(healthData.score);
      let letterGrade:string = ""
      let gradeA:number= 13;
      let gradeB:number= 27;
   
      if (score <= gradeA){
        letterGrade = "A"
      }
      else if (score <= gradeB){
        letterGrade = "B"
      }
      else  {
        letterGrade = "C"
      }
      return letterGrade;
  }
 }
}


export class KingCounty {
  placeId: string
  name: string;
  recentGrade: string;
  inspectionHistory: Array<InspectionLabels>
  constructor(placeId, healthData) {
    let name: string = "";
    let recentGrade: string = "";


    if (healthData.length > 0) {
      name = healthData[0].name
      recentGrade = healthData[0].grade;
    }
    this.placeId = placeId;
    this.name = name || "";
    this.recentGrade = recentGrade ? recentGrade.toString() : null;
    this.inspectionHistory = InspectionLabels.getInspections(healthData,  kingCountyLabels) || [];
  }
}


export class CookCounty {

  placeId: string
  name: string;
  recentGrade: string;
  inspectionHistory: Array<InspectionLabels>
  constructor(placeId, healthData) {
    let name;
    let recentGrade;

    if (healthData.length > 0) {
      name = healthData[0].aka_name
      recentGrade = healthData[0].results;
    }

    this.placeId = placeId;
    this.name = name || "";
    this.recentGrade = recentGrade || "";
    this.inspectionHistory = InspectionLabels.getInspections(healthData, cookCountyLabels) || [];

  }
}


export class TravisCounty {
   placeId: string
  name: string;
  recentGrade: string;
  inspectionHistory: Array<InspectionLabels>
  constructor(placeId: string, healthData: Array<any>) {


    if (healthData.length > 0) {
      var name = healthData[0].aka_name
      var recentGrade = healthData[0].score < 70 ? "Fail" : "Pass";
    }

    this.placeId = placeId;
    this.name = name || "";
    this.recentGrade = recentGrade || "";
    this.inspectionHistory = InspectionLabels.getInspections(healthData,  travisCountyLabels) || [];

  }
}


export const setHealthData = { "King County": KingCounty, 'New York': NewYork, "Cook County": CookCounty, "Travis County": TravisCounty }
