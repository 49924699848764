import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Public';
import React, { useState, useEffect} from 'react';
import InfoIcon from '@material-ui/icons/Info';
import TextRotateUpIcon from '@material-ui/icons/TextRotateUp';
import TextRotationNoneIcon from '@material-ui/icons/TextRotationNone';
const useStyles = makeStyles(theme => ({
  badge: {
    '& span': {
      top: "-3px",
      backgroundColor: theme.palette.secondary.switch,
    }
  }
}));

export const MapTheme = (props) => {
  const classes = useStyles();
  const { toggleMapTheme, badgeContent } = props;
  return (
    <IconButton onClick={toggleMapTheme} title="Map" color="inherit">
      <Badge badgeContent={badgeContent} className={classes.badge} >
        <MapIcon />
      </Badge>
    </IconButton>
  )
}
export const InfoCardBtn = (props) => {
  const { toggleInfoCard } = props;
  return (
    <IconButton onClick={toggleInfoCard} title="information" aria-label="show 4 new mails" color="inherit">
      <InfoIcon />
    </IconButton>
  )
}


const icons = { "verticle": getTextRotateVerticleIcon, "horizontal": getTextRotateHorizontalIcon }
export const OrientationBtn = (props) => {
  const orientations = { "horizontal": "verticle", "verticle": "horizontal" }
  const [orientation, setOrientation] = useState("verticle");
  const [iconToDisplay, setIconToDisplay] = useState("");
  
  const { toggleOrientatoin } = props;
 
  const changeOrientation = () => {
    setOrientation(orientations[orientation])
    toggleOrientatoin()
  }
  useEffect(()=>{
    const icon = icons[orientation]();
    setIconToDisplay(icon);
  }, [orientation])
  
  return (
    <IconButton onClick={()=> changeOrientation()} title="Toggle Layout" aria-label="show 4 new mails" color="inherit">
      {iconToDisplay}
    </IconButton>
  )


}

function getTextRotateVerticleIcon() {
  return <TextRotateUpIcon fontSize="large" />
}
function getTextRotateHorizontalIcon() {
  return <TextRotationNoneIcon fontSize="large" />
}