


export interface healthLabels {
  name: string,
  date: string,
  violationPoints: string,
  inspectionResult: string,
  violationDescription: string,

}

export const newYorkLabels: healthLabels =  {
  name: "dba",
  date: "inspection_date",
  violationPoints: "score",
  inspectionResult: "grade",
  violationDescription: "violation_description",

}


export const kingCountyLabels: healthLabels = {
  name: "name",
  date: "inspection_date",
  violationPoints: "violation_points",
  inspectionResult: "inspection_result",
  violationDescription: "violation_description",

}

export const cookCountyLabels: healthLabels = {
  name: "aka_name",
  date: "inspection_date",
  violationPoints: "risk",
  inspectionResult: "results",
  violationDescription: "violations",

}

export const travisCountyLabels: healthLabels = {
  name: "restaurant_name",
  date: "inspection_date",
  violationPoints: "score",
  inspectionResult: "recentGrade",
  violationDescription: "process_description",

}




export class InspectionLabels {
  violation_description: string;
  name: string;
  inspection_date: string;
  violation_points: string;

  inspection_result: string;

  constructor(name: string, date: string, violation_points: string, inspection_result: string, violation_description: string) {

    this.name = name || "";
    this.inspection_date = date || "";
    this.violation_points = violation_points || "";
    this.inspection_result = inspection_result || "";
    this.violation_description = violation_description || "";
  }

  static getInspections(healthData: Array<any>,  labels: healthLabels): Array<InspectionLabels> {
    const inspections: Array<InspectionLabels> = healthData.map(ele => {
      return new InspectionLabels(ele[labels["name"]], ele[labels["date"]], ele[labels["violationPoints"]],
        ele[labels["inspectionResult"]], ele[labels["violationDescription"]]);
    })
    return inspections;
  }
}


