import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import GeneralBtn from './../shared/GeneralBtn'
import Container from '@material-ui/core/Container';

const useStyles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
   padding: "10px",
    width: "100%",
  },
  main: {
    width: "100%",
    padding: 12,
    paddingBottom: 0,
  }
}

const ControlButtons = (props) => {
  const { classes, viewTop} = props;
  return (
    <Container  className={classes.main}>
     <div>
        <GeneralBtn styles={{ padding: 0, marginBottom: "3px" }} action={viewTop} >Top</GeneralBtn>
     </div>
      <Container style={{ padding: 0 }} disableGutters={true} className={classes.root}>
        <GeneralBtn action={(e) => props.changeViewRange(e, 'DECREMENT')} >Back</GeneralBtn>
        <GeneralBtn action={(e) => props.changeViewRange(e, 'INCREMENT')} >Next</GeneralBtn>
      </Container>
    </Container>
  )
}

export default withStyles(useStyles)(ControlButtons);