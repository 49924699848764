import React, { useState, useEffect, useRef} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useMarkerInfo from './../../container/GoogleMap/useMarkerInfo'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { cyan } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  placeListRoot: {
    cursor: "pointer",
    '&:hover': {
      backgroundColor: cyan[600],
    },
  },


}));

const ListPopUpAllResults = (props) => {
  const { setlistElements, googleData } = props
  const classes = useStyles();
  const refs = useRef(googleData.map(() => React.createRef()));
  const listOfPlaces = googleData.map((item, i) => {
    return <ListItem className={classes.placeListRoot} key={item.placeId} placeid={item.placeId} ref={refs.current[i]} >{`${item.name}`}</ListItem>
    // }
  })

  useEffect(() => {
   
    if (refs.current[0].current != null) {
      const listElements = refs.current.map(ele => {
        return ele.current;
      })
      setlistElements(listElements);
    }


  }, [googleData, setlistElements])
  return (
    <div>
      <List >
      {listOfPlaces}

        </List >
    </div>
  )
}

export default ListPopUpAllResults;