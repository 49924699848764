import React, { useState, useEffect, useRef} from 'react';
import {googleMapsApi} from './../../Api/helper';
import markers from './../../Api/markers';
import useMarkerInfo from './useMarkerInfo';
import { makeStyles } from '@material-ui/core';
import { usePickOrientation, useIsDesktop} from './../../hooks/index'

const useStyles = makeStyles(theme => ({
  initialContainer: {
  marginTop: "20px",
  marginLeft: "auto",
  marginRight: "auto",
  paddingBottom: "5px",
  maxWidth: "800px",
  },

  horizontalInitialContainer: {
    marginTop: "20px",

    paddingBottom: "5px",
    maxWidth: "800px",
    width: "100%",

    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
      maxWidth: "1000px",
      "margin-top": "100px",
    },

    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginRight: 0,
      maxWidth: "2000px",
      "margin-top": "100px",
    },
  },

  initialMap:{
    width: "100%",
    height: "350px",

    [theme.breakpoints.up('xl')]: {
      marginTop: "100px",
      height: "500px",
      width: "840px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    backgroundColor: theme.palette.secondary.background,
  },

  initialHorizontalMap: {
    width: "100%",
    height: "500px",
    [theme.breakpoints.up('md')]: {
      height: "100%",
    },
  },
  styledMapContainer: {
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
  styledMap: {
    visibility: "visible",
  },
  frontPageMap: {
    [theme.breakpoints.down('md')]: {
    },
  },
  noClick: {
    "pointer-events": "none",
  }
}));

const GoogleMapDisplay = (props) => {
  const classes = useStyles(props);
  const [mapMarkers, setmapMarkers] = useState([]);
  const { googleData,position, mapPlaceToDisplay, selectPlaceForMap, chosenMapPlaceId, placeData, getDataByPlaceId, isDataLoading,
    orientation, allPlacesTotal, healthAPILocation } = props;

  const basePaddle = "https://maps.google.com/mapfiles/kml/paddle/red-stars.png";
  const popoverInfo = useMarkerInfo(mapMarkers, googleData, selectPlaceForMap, getDataByPlaceId, mapPlaceToDisplay, placeData, healthAPILocation);
  const previousGoogleData = useRef(googleData);
  const {isDesktop} = useIsDesktop();
  useEffect(()=> {
    if (previousGoogleData.current !== googleData && googleData.length > 0) {
      const distance = [];
      googleData.forEach((ele) => {
      distance.push({ lat: ele.lat, lon: ele.lng });
    })
  
      var bounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < distance.length; i++) {
        const pointLat = distance[i]["lat"]
        const pointLon = distance[i]["lon"]
        const point = new window.google.maps.LatLng(pointLat, pointLon);
        bounds.extend(point);
      }

      googleMapsApi.map.fitBounds(bounds,0);
      googleMapsApi.map.panToBounds(bounds);
      if (distance.length === 1){
        googleMapsApi.map.setZoom(20)
      }
      // this entire IF statement will not run on first render only when updated
      // Sets previousGoogleData to indicate change to allow next update if change happens
      previousGoogleData.current = googleData;
    }
  
  }, [googleData]);


  useEffect(() => { 
      mapMarkers.forEach(ele => {
        ele.setMap(googleMapsApi.map);
      })
  }, [mapMarkers])

  useEffect(() => {
     if (googleData.length > 0) {
      const markers = googleMapsApi.getMarkers(googleData);
      setmapMarkers(prevState=> {
        prevState.forEach(ele => {
        ele.setMap(null);
        })
        return markers    
      });
    }
  }, [googleData])

  useEffect(()=> {
    const selectedIndex = googleData.findIndex((ele) => {
      return ele.placeId === chosenMapPlaceId;
    })
    //skip first render of empty array and no marker has been selected 
    if (selectedIndex >= 0 && mapMarkers.length > 0) {
        const markersToUpdate = mapMarkers;
        const prevIndex = markersToUpdate.findIndex((ele) => {
          return ele.icon.url === basePaddle;
        })

        if (prevIndex >= 0) {
          markersToUpdate[prevIndex].setIcon(markers['selected']);
        }
        if (markersToUpdate.length > 0) {
          markersToUpdate[selectedIndex].setIcon(markers['current']);
        }
    }
  }, [googleData,chosenMapPlaceId, mapMarkers])

  const initialContainer = usePickOrientation(orientation, classes.initialContainer, classes.horizontalInitialContainer, classes.initialContainer);
  const initialMap = usePickOrientation(orientation, classes.initialMap, classes.initialHorizontalMap, classes.initialMap);
  const noClickLoading = isDataLoading ? classes.noClick : "";
  const markersContainer = mapMarkers.length > 0 ? classes.styledMapContainer : ""; 
  const markersMap = mapMarkers.length > 0 ? classes.styledMap : ""; 
  const containerStyle = `${initialContainer} ${markersContainer}`
  const mapStyle = `${initialMap} ${markersMap} ${noClickLoading}`
  const style = getPositionMapStyle(placeData, position, isDesktop, allPlacesTotal);
 


 return (
   <div className={containerStyle} >
     <div className={mapStyle} style={style} id="map">
         {popoverInfo}
       </div>
     </div>
  )
}

function getPositionMapStyle(placeData, position, isDesktop, allPlacesTotal){
  
  let style = { "position": `static` };

  if (allPlacesTotal > 0){
     style = { "position": `relative` };
  }

  //  isDesktop
 if (isDesktop) {
    const isEmpty = Object.keys(placeData).length === 0;
    style = isEmpty ? { "position": `${position}`, top: "-2px" } : { "position": "relative", top: "-2px" }
  }
  return style;
}

// function distanceFromPoint(){
//     googleData.forEach((ele) => {
//       const d = getDistanceFromLatLonInKm(lat, lon, ele.lat, ele.lng);
//       distance.push({ distance: d, lat: ele.lat, lon: ele.lng });
//     })

//     distance.sort(function (a, b) {
//       return a.distance - b.distance;
//     });

//     function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
//       var R = 6371
//       // Radius of the earth in km
//       var dLat = deg2rad(lat2 - lat1);  // deg2rad below
//       var dLon = deg2rad(lon2 - lon1);
//       var a =
//         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//         Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
//         Math.sin(dLon / 2) * Math.sin(dLon / 2)
//         ;
//       var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//       var d = R * c; // Distance in km
//       return d
//     }

//     function deg2rad(deg) {
//       return (deg * (Math.PI / 180))
//     }
// }

export default GoogleMapDisplay