import  { useState, useEffect, useRef, useCallback } from 'react';
import {googleMapsApi} from '../../../Api/helper';

const placeFormats = [{
  type: "city", typeToSearch: "locality",
  primary: "long_name", secondary: "short_name", none: ""
},
{
  type: "county", typeToSearch: "administrative_area_level_2",
  primary: "long_name", secondary: "short_name", none: ""
},
{
  type: "state", typeToSearch: "administrative_area_level_1",
  primary: "short_name", secondary: "long_name", none: ""
},
{
  type: "country", typeToSearch: "country",
  primary: "short_name", secondary: "long_name", none: ""
}];


const usePlace = (id, setPlaceDataForQuery) => {
  const [place, setPlace] = useState("");
 
  // prevents useEffect from rerender like with a normal function
  // only if useCallback dependencies change
  const formatPlaceDataForQuery = useCallback(() => {
    const GoogleAddressComponents = place.address_components;
    const placeData = getAddressComponentData(GoogleAddressComponents);
    setPlaceDataForQuery(placeData);
  }, [place, setPlaceDataForQuery])

  useEffect(()=> {
    // https://developers.google.com/maps/documentation/javascript/places-autocomplete
    var input = document.getElementById(id);
    // use google api and set up listener for input to change when it does setPlace state
    googleMapsApi.autoCompletePlace(input, setPlace);
  }, [id])

  // on initial component load sets reference to current place
  const previousPlace = useRef(place);
  // get similer behavior to componentdidUpdate
  useEffect(() => {
    // when previousPlace is not equal to new sate for place from google maps autocomplete above
    if (previousPlace.current !== place) {
      formatPlaceDataForQuery();
      previousPlace.current = place;
    }
  }, [place, formatPlaceDataForQuery]);
  return place;
}

export default usePlace;

function getAddressComponentData(GoogleAddressComponents) {
  const placeData = {
    city: "",
    county: "",
    state: "",
    country: "",
  };

  placeFormats.forEach(placeFormat => {
    const googleAddress = GoogleAddressComponents.find(ele => {
      return ele.types.includes(placeFormat["typeToSearch"]);
    })
    let typeName = placeFormat["type"];

    if (googleAddress) {
      let placeName = googleAddress[placeFormat["primary"]] || googleAddress[placeFormat["secondary"]];
      placeData[[typeName]] = placeName || "";
    }
    else {
      placeData[[typeName]] = "";
    }
  })
  return placeData
}