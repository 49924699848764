import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme=> ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },

  customInput:{
    "& input": {
      background: "#f5f8fa",
      color: 'black',
    },
      "& label": {
        color: theme.palette.secondary.label
    }
  },
  rootLabel:{
    color: theme.palette.getContrastText(theme.palette.secondary.background)
  }
}));

function StyledRadio(props) {
  const classes = useStyles();
  return (
    <Radio    
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function CustomizedRadios(props) {
  const { handleTermChange, terms, searchTerm} = props;
  const radioOptions = [];
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  for (const key in terms) {
    if (terms.hasOwnProperty(key)) {
     const checked = terms[key] === searchTerm ? true:false;
      radioOptions.push(<FormControlLabel className={classes.rootLabel}  key={key} checked={checked} onChange={props.handleTermChange} value={terms[key]} control={<StyledRadio />} label={terms[key]} />)
    }
  }
  return (
    <FormControl component="fieldset">
      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
        {radioOptions}
      </RadioGroup>
      <TextField  onChange={handleTermChange} className={classes.customInput}  size="small" id="outlined-basic" label="Custom" variant="outlined" />
    </FormControl>
  );
}