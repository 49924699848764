const basePaddle = "https://maps.google.com/mapfiles/kml/paddle/";
const whiteMarker = 'wht-circle.png';
const purpleMarker = 'purple-stars.png';
const redMarker = "red-stars.png";
let defaultMarker = { url: basePaddle + purpleMarker, };
   if (window?.google){
     defaultMarker =  {
   
     url: basePaddle + purpleMarker,
         origin: new window.google.maps.Point(0, 0),
           anchor: new window.google.maps.Point(0, 22),
             size: new window.google.maps.Size(30, 32),
               scaledSize: new window.google.maps.Size(35, 35)
     }

  }

let selectedMarker = { url: basePaddle + whiteMarker}
if (window?.google) {
   selectedMarker = {
     url: basePaddle + whiteMarker,
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 22),
    size: new window.google.maps.Size(30, 32),
    scaledSize: new window.google.maps.Size(35, 35)
}
}

let currentMarker = {  url: basePaddle + redMarker,}
if (window?.google) {
 currentMarker = {
  url: basePaddle + redMarker,
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 22),
  size: new window.google.maps.Size(30, 32),
  scaledSize: new window.google.maps.Size(35, 35)
}
}
const markers = { 'selected': selectedMarker, 'default': defaultMarker, 'current': currentMarker }
export default markers;

