import React from 'react';
import  makeStyles  from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: '0px',
  },
  rootAddress: {
     width: "100%", 
     padding: 0,
  },
   rootDeskTop:{
     padding: "0px",
   },
  nested: {
    paddingLeft: theme.spacing(4),
    display: 'block',
  
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  // justifyContent: 'space-evenly',
    justifyContent: 'space-around',
   width: '310px',
  },
  adddressItem: {
     "text-align": "center", 
    padding: 0
  },
  item: {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '2px',
    paddingRight: '2px',
  },

  siteLink: {
  textDecoration: "none",
    color: theme.palette.text.secondary,
    marginRight: "5px",
  },
  hoursText: {
    marginBottom: '8px',
  }
}));

 const GeneralInfo  = (props) => {
   const classes = useStyles(props);
   const [open, setOpen] = React.useState(false);

 
   const { generalInfo,} = props;
   const {phoneNumber, priceLevel, address, hours,  website, } = generalInfo;

   const handleClick = () => {
     setOpen(!open);
   };

  const price = `Price: ${priceLevel}`
   const hoursDisplay = hours.map((ele,i)=> {
     return <ListItemText key={i} className={classes.hoursText} primary={hours[i]} />
   })
 
   const forAddressItem = `${classes.item} ${classes.adddressItem}`;
   const forAddressRoot = `${classes.root} ${classes.rootAddress}`;

   return (
     <div className={classes.container}>
       <List 
         component="ul"
         aria-labelledby="nested-list-subheader"

         className={forAddressRoot}
       >
         <ListItem className={forAddressItem}>
           <ListItemText primary={address} />
         </ListItem>
       </List>
       <List
        component="ul"
        aria-labelledby="nested-list-subheader"
         className={classes.root }
      >
         <ListItem className={classes.item}>
          <ListItemText primary={phoneNumber} />
        </ListItem>
         <ListItem className={classes.item} > 
           <ListItemText primary={price} />      
        </ListItem>
      </List>
      <List
        component="ul"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
         <ListItem className={classes.item} >
           <a className={classes.siteLink} href={website} target={"_blank"}><ListItemText primary={"Menu"} />
           </a>
         </ListItem>
         <ListItem className={classes.item} button onClick={handleClick}>
          <ListItemText primary="Hours" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem> 
      </List>
      <Collapse style={{ display: 'block' }} in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            {hoursDisplay}     
          </ListItem>
        </List>
      </Collapse>
    </div>
  
  )
}

export default GeneralInfo

