import React, { useState, useEffect, useReducer } from 'react';
import DisplayCard from './../../components/DisplayCard/DisplayCard'
import QuickSearch from '../../components/QuickSearch/index';
import ControlButtons from './../../components/ControlButtons/index';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import { useIsDesktop, useIsShowHealth} from './../../hooks/index'
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ListButton from './../../components/ListButton'

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    width: "175px",
    bottom: 0,
    right: "2px",
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      width: "155px",
      right:"-6px",
    },
  },

  main: {
  paddingTop:  "90px",
    [theme.breakpoints.down('sm')]: {
      paddingTop: props => props.orientation === "default" ? '20px' : "90px",
    },

  },
  mainMobileTopMap: {
    paddingTop: "10px",

  },
  counter:{
    alignSelf: "center"
  },
}));

const Results = (props) => {

  const [placesViewRange, dispatchPlacesViewRange] = useReducer(navigatePlacesViewRange,{start:0, end:0});
  const [currentPlacesToDisplay, setCurrentPlacesToDisplay] = useState([]);
  const [checked, setChecked] = useState(false);
 

  const { placesToDisplay, getMorePlaces, displayInc, changeMapIcon, setAllPlaces, orientation,
    setPlaceDataForQuery, allPlacesCount, placeData, isShowQuickSearch, isDataLoading, 
    googleData, selectPlaceForMap, getDataByPlaceId, mapPlaceToDisplay, healthAPILocation} = props;
  const classes = useStyles(props);
  const totalPlacesDisplay = placesToDisplay.length;
  const startingInc = totalPlacesDisplay > displayInc ? displayInc : totalPlacesDisplay;
  const startingDisplayCount = placesViewRange.start + startingInc;
  const skeletonDisplayCount =  displayInc;
  const { isDesktop } = useIsDesktop();
  const horizontalKey = "default"
  const main = !isDesktop && orientation === horizontalKey ? classes.mainMobileTopMap: classes.main;
  const oneResultStyle = totalPlacesDisplay === 1 && isDesktop ? { marginRight: "50px", marginLeft: "30px" } : {};
  // const isShowHealth = useIsShowHealth(placeData);
  const isGoogleData = googleData.length > 0;

  useEffect(()=> {
    dispatchPlacesViewRange({ type: "INITIAL", payload: { totalPlacesDisplay, displayInc } });
  }, [ displayInc, totalPlacesDisplay])

  useEffect(() => {
   const {start, end} = placesViewRange;
   const partialDisplay = placesToDisplay.slice(start,end)
   setCurrentPlacesToDisplay(partialDisplay)
  }, [placesViewRange, placesToDisplay])

  let toDisplay = null;
  if (totalPlacesDisplay > 0) {
    toDisplay = currentPlacesToDisplay.map((ele, i) => {
      return <DisplayCard isDesktop={isDesktop} healthAPILocation={healthAPILocation} placeData={placeData}
        changeMapIcon={changeMapIcon} key={i} googleYelpHealthData={ele}/>
    })
  } 

  const changeViewRangeHandler = (e,type) => {
    if (type === 'INCREMENT' && placesViewRange.end === totalPlacesDisplay){
      getMorePlaces();
    }
    else{
      dispatchPlacesViewRange({ type, payload: { totalPlacesDisplay, displayInc } });
    }
  }

  const viewTop = () => {
    const main = !isDesktop && orientation === horizontalKey ? "topMobile" :"topDesktop";
    document.getElementById(main).scrollIntoView();
  }

  useEffect(() => {
    if (placesToDisplay.length > 0) {
      setChecked(true);
    }
  }, [placesToDisplay])

  return (
    <div className={main} id={'topDesktop'} style={oneResultStyle}>
      {isGoogleData && <ListButton placeData={placeData} googleData={googleData} healthAPILocation={healthAPILocation}
        selectPlaceForMap={selectPlaceForMap} getDataByPlaceId={getDataByPlaceId} mapPlaceToDisplay={mapPlaceToDisplay}/>
    }
      {isShowQuickSearch && <QuickSearch setAllPlaces={setAllPlaces} setPlaceDataForQuery={setPlaceDataForQuery} />}
      <div className={classes.cardLocation}>
  
        {isDataLoading ? (
          <div className="card-container">{
            [...new Array(skeletonDisplayCount)].map((ele, i) => {
              return (<div key={i} >
                <Skeleton variant="rect" width={320} height={155} />
                <Skeleton variant="text" />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="rect" width={320} height={310} />
              </div>)
            })
          }
          </div>
        ) : (
            <div className="card-container">{toDisplay}</div>
          )}
   </div>
      <Slide timeout={{ enter: 1000, exit: 500 }} direction="up" in={checked} mountOnEnter unmountOnExit>
        <div className={classes.root} >
          <ControlButtons changeViewRange={changeViewRangeHandler} viewTop={viewTop}/>
          <Typography color="textPrimary" varient={"caption"} className={classes.counter} >
            {startingDisplayCount} / {allPlacesCount}
          </Typography>
        </div>
      </Slide>
    </div>
  )
}

function navigatePlacesViewRange(currentState, action) {
  const placesViewRange = { ...currentState};
  const { totalPlacesDisplay, displayInc } = action.payload;
  const incrementEnd = placesViewRange.end + displayInc;
  const incrementStart = incrementEnd - displayInc;
  
  switch (action.type) {
    case "INITIAL":
      const initialStart = totalPlacesDisplay - displayInc > 0 ? totalPlacesDisplay - displayInc : 0;
      const initialEnd = totalPlacesDisplay;
      return { start: initialStart, end: initialEnd};
    case "INCREMENT":  
      // If increment reaches beyond places total length then just return places total length;
      placesViewRange.end = incrementEnd < totalPlacesDisplay ? incrementEnd : totalPlacesDisplay;
      placesViewRange.start = incrementStart;
      return placesViewRange; 
    case "DECREMENT": 
     placesViewRange.start = placesViewRange.start - displayInc > 0 ?  placesViewRange.start - displayInc : 0;
     placesViewRange.end = placesViewRange.start + displayInc;
      return placesViewRange;
    default:
      break;
  }
}

export default Results;