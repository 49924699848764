import React, { useState, useEffect, useRef, useReducer } from 'react';
import usePlace from './Place/index';
import {googleMapsApi} from '../../Api/helper';
import {AllPlaces} from './../../Models/place'
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { WarnPopover } from "./../../components/PopOver/index";
import { useIsDesktop } from '../../hooks';
const useStyles= makeStyles(theme => {

  return {
    container: {
      width: '100%',
      maxWidth: "500px",
      marginRight: '1%',
      [theme.breakpoints.down('sm')]: {
        marginRight: '.5%',
      },
  },
    search: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      marginTop: 6,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
      },
    },
    inputRoot: {
      color: 'inherit',
      display: 'block',
      cursor: 'none',
      zIndex: 0,
      width: "100%",
    
    },
    inputInput: {
      height: 30,
       boxSizing: 'border-box',
      padding: theme.spacing(1, 1, 1, 1),
      width: '100%',
      "font-size": "16px",
      
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxWidth: "490px",
        
      },
      // [theme.breakpoints.down('sm')]: {
      //   "font-size": "16px",

      // },
    },
    searchIcon: {
      width: theme.spacing(4),
      height: '100%',
      position: 'relative',
      top: "3px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      zIndex: 2,
    },
  }
})

const Search = props => {
  const classes = useStyles();
  const fillOutText = "Fill out all fields";
  const emptyPlace = "Select location from top text drop down"
  const noResults = "No results"
  const [inputForSearch, setInputForSearch] = useState("");
  const [allPlaces, setPlaces] = useState([]);
  const [placeInputTyped, setInputPlace] = useState("");
 // const [anchorEl, setanchorEl] = useState(null);
  const [warnState, setanchorEl] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { anchorEl: null, text: fillOutText }
  )
  const { isDesktop } = useIsDesktop();
  const placeHolderLocation = "Search City";
  const placeHolderFood = isDesktop ? "Burgers, Pizza, Tacos, ect.." : "Search For food"
  const { setAllPlaces, setPlaceDataForQuery} = props
  const searchPlaceId = "searchPlace";
  

  const placeInputSelected = usePlace(searchPlaceId, setPlaceDataForQuery);

  //SEPERATE OUT INTO HOOK
  const isFirstRun = useRef(true);
  useEffect(()=>{
    if(isFirstRun.current){
      isFirstRun.current = false;
      return;
    }
    if (allPlaces.length < 1) {
      return;
    }

    formatAllPlaceDataForQuery();
    function formatAllPlaceDataForQuery() {
      let locationData = allPlaces.map(ele => {
        const allPlace = new AllPlaces(ele)

        return allPlace;
      })
     setAllPlaces(locationData);
    }
  }, [allPlaces, setAllPlaces])


  const handleChange = (event) =>{
    setInputForSearch(event.target.value );
  }


  const searchPlaces = (event) =>{
    const getPlaces = (places) => {
      if (places.length < 1) {
        setanchorEl({ anchorEl: event.currentTarget, text: noResults });
      }
      else {
        setPlaces(places);
      }
    }
    googleMapsApi.findPlaces(inputForSearch, getPlaces);
  }

      
  const handleKeyPressSearch =(event)=> {
  if (event.key === "Enter") {
    event.preventDefault();
    // when enter is pressed it could be for either searching or selecting autocomplete so must 
    // check for all posibilities 
    if (placeInputSelected === "" && placeInputTyped === "" && inputForSearch==="") {
    setanchorEl({ anchorEl: event.currentTarget, text: emptyPlace });
    }
    if (placeInputSelected === "" && placeInputTyped === "" && inputForSearch !=="") {
    setanchorEl({ anchorEl: event.currentTarget, text: emptyPlace });
    }
    else if (inputForSearch !== "" && placeInputSelected !== "") {
      searchPlaces(event);
      }   
    }
  }


  const handlePlaceChange = (event) => {
    setInputPlace(event.target.value);
  }


  const handleSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      checkInputResult(event);
     document.querySelector("#searchs").blur();
    }
     if (event.type === "click"){
      checkInputResult(event);
    }
  }


  const popOver = warnState.anchorEl == null ? null : <WarnPopover setanchorEl={(inp) => setanchorEl({ anchorEl: inp})} anchorEl={warnState.anchorEl} warningText={warnState.text} />
  const content =  (
    <div className={classes.container}  >
      <div className={classes.search} onKeyPress={handleKeyPressSearch}>
        
        <InputBase type="text" onChange={handlePlaceChange} id={searchPlaceId} placeholder={placeHolderLocation}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}/>
        </div>
      {popOver}
      <div className={classes.search} onKeyPress={handleSearch}>
       
        <InputBase id={'searchs'}  type="text" onChange={handleChange}
              placeholder={placeHolderFood}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'specific' }}
              />
        <div className={classes.searchIcon} onClick={handleSearch}>
          <SearchIcon  style={{ cursor: 'pointer' }} />
        </div>
        </div>
     
     </div>
      
  )
  return content;

  function checkInputResult(event){
    if (placeInputSelected === "" ) {
      setanchorEl({ anchorEl: event.currentTarget, text: emptyPlace });
      }
    else if (inputForSearch === ""){
      setanchorEl({ anchorEl: event.currentTarget, text: fillOutText });
    }
    else if (inputForSearch !== "" && placeInputSelected !== ""){
      searchPlaces(event)
    }
  }
}

export default Search;