
export class GooglePlace {
  placeId: string
  name: string
  rating: string
  photo: string
  address: string
  reviewsTotal: number
  reviews: string
  url: string
  constructor(placeId:string, googleData: any, detailsObject: any){
    const { reviews, url }: { reviews:string, url:string }  = detailsObject;

    this.placeId = placeId;
    this.name = googleData.name || "";
    this.rating = googleData.rating || "";
    this.photo = googleData.photo || "";
    this.address = googleData.address || "";
    this.reviewsTotal = googleData.reviewsTotal || 0;
    this.reviews = reviews || "";
    this.url = url || "";
  }
}

export class Yelpplace {
  placeId: string;
  name: string;
  rating: string;
  yelpId: string;
  url: string;
  reviewCount: number
  constructor(placeId,yelpData ) {
    let name:string = "";
    let rating:string = "";
    let yelpId:string = "";
    let url:string = "";
    let reviewCount:number = 0;

    if (Object.entries(yelpData).length > 0 && yelpData.constructor === Object){//DO empty oBJ keep consistant
      name = yelpData.name;
      rating = yelpData.rating;
      yelpId = yelpData.id;
      url = yelpData.url;
      reviewCount = yelpData.review_count
    }
    this.placeId = placeId;
    this.name = name;
    this.rating = rating;
    this.yelpId = yelpId;
    this.url = url;
    this.reviewCount = reviewCount
  }
}

export class Healthplace {
  placeId: string;
  name: string;
  recentGrade: string;
  inspectionHistory: Array<any>;
  constructor(placeId, healthData) {
    let name:string = "";
    let recentGrade:string = "";

    if (healthData.length > 0){
      name = healthData[0].name
      recentGrade = healthData[0].grade;
    }
    this.placeId = placeId;
    this.name = name;
    this.recentGrade = recentGrade;
    this.inspectionHistory = healthData || [];
  }
}


export class AllPlaces {
  placeId: string;
  name: string;
  address: string;
  rating: string;
  photo: string;
  lat: number;
  lng: number;
  marker: string;
  reviewsTotal: number;

  constructor(place:any){
    let addressFull:Array<string> = place.formatted_address.split('');
    let index: number = addressFull.indexOf(','); // don't need the city and state
    addressFull = addressFull.slice(0, index); 
    let address: string = addressFull.join('');
    let photo:string = place.photos != null ? place.photos[0] : null;

    this.placeId= place.place_id;
    this.name= place.name;
    this.address = address
    this.rating= place.rating;
    this.photo = photo;
    this.lat= place.geometry.location.lat();
    this.lng= place.geometry.location.lng();
    this.marker= 'default';
    this.reviewsTotal = place.user_ratings_total || 0;
  }
}


export class GeneralInfo {
  placeId: string;
  hours: Array<string>;
  priceLevel: string;
  address: string;
  website: string;
  phoneNumber: string;
  constructor(placeId:string, placeDetails:any, allplaces:any, yelpData:any) {
    const hours:any = placeDetails.opening_hours || null;
    const allDays:Array<string> = hours != null ? hours.weekday_text : ["None Available"]
    this.placeId = placeId;
    this.hours = allDays;
    this.priceLevel = yelpData.price || "";
    this.address = allplaces.address || "None Available";
    this.website = placeDetails.website || "";
    this.phoneNumber = placeDetails.formatted_phone_number || "None Available";
  }
}