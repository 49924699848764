import { useEffect, useRef, useState } from 'react';
import { checkMatchPlaceData} from './../Api/helper';
export const useDidUpdateEffect = (fn, inputs = []) => {
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current){
      isInitialRender.current = false;
    }
    else{
      fn();
    }
  }, [fn, inputs]);
}

export const useIsDesktop = () => {
  const tabletSize = 900;
  const largeDesktop = 1920;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > tabletSize);
  const [isDesktopLarger, setisLarger] = useState(window.innerWidth > largeDesktop);
  
    useEffect(() => {
     
      updateWindow();
        window.addEventListener("resize", updateWindow);
     

    }, [])

  return { isDesktop, isDesktopLarger};

  function updateWindow() {
    setIsDesktop(window.innerWidth > tabletSize)
    setisLarger(window.innerWidth > largeDesktop);
  }
}


export const usePickOrientation = (orientation, verticleDisplay, horizontalDisplay,mobileStyle=null) => {
  const isDisplaySize =  useIsDesktop();
  const mobile = { 'default': mobileStyle, 'switch': verticleDisplay };
  const deskTop = { 'default': horizontalDisplay, 'switch': verticleDisplay  }
    const deskTopLarger = { 'default': horizontalDisplay, 'switch': verticleDisplay }
    let toDisplay = null;
    if (isDisplaySize.isDesktopLarger) {
      toDisplay = deskTopLarger[orientation]
    }
    else if (isDisplaySize.isDesktop) {
      toDisplay = deskTop[orientation]
    }
    else {
      toDisplay = mobile[orientation]
    }
    return toDisplay
}

export const useIsShowHealth = (placeData) => {
  const [isShowHealth, setIsShowHealth] = useState(false);

  useEffect(() => {
    if (placeData.county) {
      let isShowHealth = checkMatchPlaceData(placeData);
      setIsShowHealth(isShowHealth);
    }
  }, [placeData])
  return isShowHealth;
}


