import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { availableHealthAPI } from '../../Api/constants';
import { googleMapsApi, getYelpHealthData, buildYelpQuery, buildHealthQuery} from '../../Api/helper'
import { GooglePlace, Yelpplace,  GeneralInfo} from './../../Models/place'
import { setHealthData} from "./../../Models/HealthLocations"
import Results from './../Results/index';
import AppBar from './../AppBar/index';
import GoogleMapDisplay from './../GoogleMap/index';
import Content from './../Content/index';
import StaticDynamicInitialMap from '../../components/StaticDynamicInitialMap';
import Footer from './../../components/Footer/index';
import { localAdd, localupdate } from '../../utils/localstorage';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      allPlaces: [],
      placesDetails: [],
      googleData: [],
      yelpData: [],
      healthData:[],
      placesToDisplay: [],
      displayPlaceResults:[],
      googleDataForList: [],
      photos: [],
      placeData: {},
      isDataLoading: false,
      healthAPILocation: null,
      orientation: 'default',
      currentTotalDisplay: 0,
      resultsDisplayCount: 0,

      chosenMapPlaceId: "",
      mapPlaceToDisplay: {},
      allMapData: {
        selectedplace: {}, 
        selectedplaceId: "",
      },

     }
    this.displayInc = 6;
  }
 
  componentDidUpdate(prevProps, prevState){
    if (prevState.googleData !== this.state.googleData ){
      this.displayNewPlaces();
    }
    if (this.state.yelpData !== prevState.yelpData && this.state.healthData !== prevState.healthData && this.state.photos !== prevState.photos && (this.state.healthData.length > 0 || this.state.yelpData.length > 0)){
      this.combineDataForPlacesToDisplay()
    }
    if (this.state.allMapData.selectedplaceId !== prevState.allMapData.selectedplaceId){
      this.getDataForMap();
    }
    if (this.state.placesToDisplay !== prevState.placesToDisplay && this.state.resultsDisplayCount >= this.state.placesToDisplay.length){
      this.updateResultsDisplay();
    }
    if (this.state.placesToDisplay !== prevState.placesToDisplay && this.state.resultsDisplayCount < this.state.placesToDisplay.length) {
      this.getmapPlaceToDisplay();
    }
    if (this.state.placesToDisplay !== prevState.placesToDisplay && this.state.allMapData.selectedplaceId !== prevState.allMapData.selectedplaceId){
      this.changeMapToDisplay();
    }
    if(this.state.placeData !== prevState.placeData){
     this.setHealthAPILocation();
    }
 
  }

  componentDidMount() {
  this.getInitialOrientation();

   // // used for testing
    // localupdate("placeData", this.placesLocal);
    // const seattleCoordinates = { lat: 47.6062095, lng: -122.3320708, }
    // googleMapsApi.initiateMap(seattleCoordinates.lat, seattleCoordinates.lng);
    //  localupdate("allPlaces", this.placesLocal);
    // localupdate("displayPlaceResults", this.placesLocal);

  }

  getInitialOrientation(){
    const key = "orientation"
    const setStateCallBack = (key, value) => {
      this.setState({ orientation: value })
    }
    localupdate(key, setStateCallBack);
  }

  displayNewPlaces = () => {
    this.setState(currentState => {
      return { placesToDisplay: [], yelpData: [], healthData: [], displayPlaceResults:[] }
    }, this.getPlacesToDisplay);
  }

  getPlacesToDisplay = async (isLoading = true) =>{
    if (isLoading){
       this.setIsDataLoading(true);
     } 
 
    let details = await this.getDetails();
    let queries = this.buildQueries(details.details, details.slectedPlaces);
    const setData = (data) => {
      this.setState(currentState => {
        const yelpData = currentState.yelpData.concat(data.yelpData)
        const healthData = currentState.healthData.concat(data.healthData)
        const placesDetails = currentState.placesDetails.concat(details.details)
        const photos = currentState.placesDetails.concat(details.photos)
        return { yelpData, healthData, placesDetails, photos}
      });
    }
    getYelpHealthData(queries, setData);
  }
  combineDataForPlacesToDisplay  = () => {
    const { allPlaces, placesToDisplay, currentTotalDisplay, yelpData,healthData,photos } = this.state;
    const start = placesToDisplay.length;
    const end = currentTotalDisplay;
    const slectedPlaces = allPlaces.slice(start, end);
    const slectedYelpData = yelpData.slice(start, end);
    const slectedHealthData = healthData.slice(start, end);
    const places = this.getPlacesDisplay(slectedPlaces, slectedYelpData, slectedHealthData, photos);

    this.setState(currentState => {
      const placesToDisplay = currentState.placesToDisplay.concat(places)
      
      return { placesToDisplay}
    }, this.setIsDataLoading(false));
  }

  getPlacesDisplay = (slectedPlaces, slectedYelpData, slectedHealthData, photos) => {

    const location = this.state.healthAPILocation || "King County"
    const places = slectedPlaces.map((ele, i) => {
      const yelp = slectedYelpData[i].yelp;// USE getDataByPlaceIds
      const health = slectedHealthData[i].data; /// USE getDataByPlaceIds
    //  debugger;
      const healthPlace = new setHealthData[location](slectedHealthData[i].placeId, health, location)
      // new Healthplace(slectedHealthData[i].placeId, health)
      const yelpPlace = new Yelpplace(slectedYelpData[i].placeId, yelp);
      const detailsData = this.getDataByPlaceId("placesDetails", ele.placeId);
      const generalInfo = new GeneralInfo(ele.placeId, detailsData, ele, yelp);
 
      const selectedPhoto = photos.find(pic => {
        return pic.placeId === ele.placeId;
      })
      return { googlePlace: new GooglePlace(ele.placeId, ele, detailsData), yelpPlace,
         healthPlace, generalInfo, photo: selectedPhoto }
    })
    return places;
  }

  getDataByPlaceId = (dataToLook,placeId) => {
    let foundData = null; 
    switch (dataToLook) {
      case "placesDetails":
        const placesDetails=  [...this.state["placesDetails"]];
        foundData  = placesDetails.find(ele=> {
          return ele.place_id === placeId
            })
        break;
 
      case "allPlaces":
        const allPlaces = [...this.state["allPlaces"]];
        foundData =  allPlaces.find(ele=> {
          return ele.placeId === placeId
            })
        break;
      default:
        break;
    }
    return foundData;
 
  }

  setAllPlaces = (data) => {
    const currentTotalDisplay = this.displayInc >= data.length ? data.length : this.displayInc;
    const resultsDisplayCount = currentTotalDisplay;
    // AllPlaces will move around in different orders based on map selection and googleData Needs to stay in the same order to corrospond with map markers.
    const googleData = [...data];
    const googleDataForList = [...data];
   // localAdd("allPlaces", data);
    this.setState({ allPlaces: data, googleData, googleDataForList, currentTotalDisplay, resultsDisplayCount });
  }

  setPlaceDataForQuery = (placeData) =>{
 // localAdd("placeData", placeData);
    this.setState({ placeData  });
  }

  setHealthAPILocation = () => {
    const { placeData} = this.state;
    const properties = ['city', 'county'];
    let foundHealthApi = null;
  
    for (let i = 0; i < properties.length; i++) {
      const ele = properties[i];
      foundHealthApi = availableHealthAPI.find(element => element === placeData[ele]);
      if (foundHealthApi){
        this.setState({ healthAPILocation: foundHealthApi });
        break;
      }
    }
  }

       // availableHealthAPI

   buildQueries(phoneNumbers, slectedPlaces) {
     const { placeData, currentTotalDisplay, placesToDisplay, healthAPILocation} = this.state;
    const startingCountDisplay = placesToDisplay.length
    const displayLimit = currentTotalDisplay - startingCountDisplay;
    const queries = { displayLimit};
   
     queries["health"] = buildHealthQuery(slectedPlaces, placeData, phoneNumbers, healthAPILocation);
    queries["yelp"] =  buildYelpQuery(slectedPlaces, phoneNumbers, placeData);

    return queries;
  }
  async getDetails(){
    const { allPlaces, currentTotalDisplay, placesToDisplay } = this.state;
    const startingCountDisplay = placesToDisplay.length;
    const slectedPlaces = allPlaces.slice(startingCountDisplay, currentTotalDisplay);

    const photos = await this.getPhotos(slectedPlaces);
    const getDetails = googleMapsApi.getDetails(slectedPlaces);
    const details = await getDetails;
    return { slectedPlaces, details, photos}
  }

  async getPhotos(slectedPlaces){
    const photos = slectedPlaces.map(ele=> {
      const url = ele.photo != null ? ele.photo.getUrl() : null;
      const photo = {
        placeId: ele.placeId,
        photo: url,
      }
      return photo;
    })
    return photos;
  }
  updateResultsDisplay = () => {

    this.setState(currentState => {
      const start = currentState.displayPlaceResults.length
      const end = currentState.resultsDisplayCount;
      const toDisplay = currentState.placesToDisplay.slice(start, end);
      const displayPlaceResults = currentState.displayPlaceResults.concat(toDisplay);
  //   localAdd("displayPlaceResults", displayPlaceResults);
      return { displayPlaceResults}
    });
  }
  getMorePlaces = (displayNumber = null) => {
    const displayInc = displayNumber || this.displayInc;

    const allPlacesTotal = this.state.allPlaces.length
    if (this.state.currentTotalDisplay < allPlacesTotal) {
      const totalInc = displayInc + this.state.displayPlaceResults.length;
      const isResultsSmaller =  totalInc <= this.state.currentTotalDisplay ? true : false

      const currentTotalDisplay = totalInc >= allPlacesTotal ? allPlacesTotal : totalInc
      const resultsDisplayCount = currentTotalDisplay;
      if (isResultsSmaller){
        this.setState({ currentTotalDisplay, resultsDisplayCount }, this.updateResultsDisplay);
      }
      else{
        this.setState({ currentTotalDisplay, resultsDisplayCount },  this.getPlacesToDisplay);
      }
    }
  }
  getMapPlaces  = () => {
    const displayInc = 1;

    const allPlacesTotal = this.state.allPlaces.length
    if (this.state.currentTotalDisplay < allPlacesTotal) {
      const totalInc = displayInc + this.state.placesToDisplay.length;
      const currentTotalDisplay = totalInc >= allPlacesTotal ? allPlacesTotal : totalInc
      this.setState({ currentTotalDisplay }, () => this.getPlacesToDisplay(false));
    }
  }

  selectPlaceForMap = (displaySelected) =>{
    this.setState(currentState => {
  
      const newState = { selectedplace: displaySelected}
      const allMapData = { ...currentState.allMapData, ...newState};

      allMapData.selectedplaceId = displaySelected.placeId;
      return { allMapData}
    }, this.changeMapIcon(displaySelected.placeId));
  }
  getDataForMap = async () => {
    const { placesToDisplay, allPlaces} = this.state;
    const { selectedplaceId} = this.state.allMapData;
    const mapPlaceToDisplay = this.getMatchingToDisplayData(selectedplaceId, placesToDisplay);

    if (mapPlaceToDisplay) {
      this.setState({ mapPlaceToDisplay }, this.changeMapIcon(selectedplaceId));
    }
    else{
      const foundIndex = allPlaces.findIndex(ele => ele.placeId === selectedplaceId)
      const placeIndex = placesToDisplay.length;
    // Swap user selected place from map in allPlaces so it comes next when user hits the next button
      const swapallPlaces = (placeToswap, target) => {
        this.setState(currentState => {
          const allPlaces = [...currentState.allPlaces]
          const temp = allPlaces[placeToswap]
          allPlaces[placeToswap] = allPlaces[target]
          allPlaces[target] = temp;
          return { allPlaces }
        }, this.getMapPlaces);
      }
      swapallPlaces(placeIndex, foundIndex);
    }
  }
  getmapPlaceToDisplay = () => {
    const {placesToDisplay} = this.state;
    const { selectedplaceId} = this.state.allMapData
    const mapPlaceToDisplay = this.getMatchingToDisplayData(selectedplaceId,placesToDisplay);
    this.setState({ mapPlaceToDisplay });
  }
  getMatchingToDisplayData = (placeId, placesToDisplay) => {
    const found = placesToDisplay.find(ele => ele.googlePlace.placeId === placeId)
    return found;
  }
  changeMapToDisplay = () => {
    const {placesToDisplay} = this.state;
    const { selectedplaceId } = this.state.allMapData;
    const mapPlaceToDisplay = placesToDisplay.find(ele=> {
      return ele.googlePlace.placeId === selectedplaceId;
    })
    this.setState({ mapPlaceToDisplay });
  }
  changeMapIcon = (placeId, event) =>{
    this.setState({chosenMapPlaceId: placeId} )
  }
  setIsDataLoading = (isLoading) => {
    this.setState({ isDataLoading: isLoading });
  }
  placesLocal = (key,value) => {
    this.setState({  [key]: value });
  }

  toggleOrientatoin =() => {
    const key = "orientation"
    this.setState(currentState=> {
      const orientations = { "default": "switch", "switch": "default" }
      const nextOrientation = orientations[currentState.orientation]
      localAdd(key, nextOrientation);
      return {orientation: nextOrientation}
    })
  }
  getIsTotal = (orientation, allPlacesTotal) => {
    let isTotal = null;
    if (orientation === "default"){
     isTotal = allPlacesTotal > 0
    }
    else if (orientation === "switch"){
       isTotal = allPlacesTotal <= 0
    }
    return isTotal;
  }
  render() { 
    const { chosenMapPlaceId, mapPlaceToDisplay, displayPlaceResults, orientation,
      googleData, googleDataForList, allPlaces, healthAPILocation, placeData, isDataLoading, photos, resetMapPlaceToDisplay } = this.state;
    const { mapTheme, classes} = this.props;
    const isShowQuickSearch = Object.keys(placeData).length === 0 && placeData.constructor === Object ? true : false;
    const allPlacesTotal = allPlaces.length;
    const isTotal = this.getIsTotal(orientation, allPlacesTotal);
    const initialMapPosition = isTotal ? "relative" : "absolute";

    return (    
      <div className={classes.base} >
        <AppBar toggleMapTheme={this.props.toggleMapTheme} mapTheme={mapTheme} setPlaceDataForQuery={this.setPlaceDataForQuery} setAllPlaces={this.setAllPlaces} allPlacesTotal={allPlacesTotal} toggleOrientatoin={this.toggleOrientatoin}/>
    
        {allPlacesTotal === 0 &&  <StaticDynamicInitialMap orientation={orientation} mapTheme={mapTheme} /> } 
        <Content orientation={orientation} allPlacesTotal={allPlacesTotal} isDataLoading={isDataLoading}
          results={
           <Results placesToDisplay={displayPlaceResults} photos={photos} changeMapIcon={this.changeMapIcon} getMorePlaces={this.getMorePlaces} placeData={placeData} isShowQuickSearch={isShowQuickSearch}
              setAllPlaces={this.setAllPlaces} setPlaceDataForQuery={this.setPlaceDataForQuery} displayInc={this.displayInc} allPlacesCount={allPlacesTotal} isDataLoading={isDataLoading} orientation={orientation}
              googleData={googleDataForList} selectPlaceForMap={this.selectPlaceForMap} getDataByPlaceId={this.getDataByPlaceId} mapPlaceToDisplay={mapPlaceToDisplay} healthAPILocation={healthAPILocation}/>}
       
          googleMapDisplay={
           <GoogleMapDisplay googleData={googleData} photos={photos} chosenMapPlaceId={chosenMapPlaceId} selectPlaceForMap={this.selectPlaceForMap} mapPlaceToDisplay={mapPlaceToDisplay} placeData={placeData}
              resetMapPlaceToDisplay={resetMapPlaceToDisplay} isDataLoading={isDataLoading} orientation={orientation} position={initialMapPosition} allPlacesTotal={allPlacesTotal} healthAPILocation={healthAPILocation}/>}
        />
      
        <Footer orientation={orientation}/>
      </div>
     )
  }
}
export default withStyles(styles)(Layout);


function styles(theme){
  const styles = {
    base: {
      textAlign: "center",
      minHeight: "100vh",
      backgroundColor: theme.palette.secondary.background,
    },
  }
  return styles;
}
