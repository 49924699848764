import excelent from './../../../assets/health/excellent.png'
import good from './../../../assets/health/good.png';
import okay from './../../../assets/health/okay.png';
import improve from './../../../assets/health/improve.png';



// New York
import A from './../../../assets/health/NewYork/A.png'
import B from './../../../assets/health/NewYork/B.jpg';
import C from './../../../assets/health/NewYork/C.jpg';

// Cook County
import failCookCounty from './../../../assets/health/CookCounty/fail.png';

const kingCounty: Record<string, string> = { '1': excelent, '2': good, '3': okay, '4': improve };
const newYork: Record<string, string> = {"A":A,"B":B,"C":C,}
const cookCounty: Record<string, string> = { "Pass": A, "Fail": failCookCounty}
const travisCounty: Record<string, string> = { "Pass": A, "Fail": failCookCounty }
const locations: Record<string, object> = { "New York": newYork, "King County": kingCounty, "Cook County": cookCounty, "Travis County": travisCounty }


// TEST THIS FUNCTION

/**
 * @param {string} [Test] - Somebody's name.
 */
export function getRatingImage(healthPlace,location):object {
  const recentGrade:string = healthPlace.recentGrade
  const pictureSet:object = locations[location];
  const healthImage:string = recentGrade ? pictureSet[recentGrade] : null;
  const isImage: boolean = !!healthImage;
  const showRating:string = isImage ? null : recentGrade || null;// if image exists no need for rating, but if no rating then return null
  return { healthImage, showRating}
}

