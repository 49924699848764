import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import  makeStyles from '@material-ui/core/styles/makeStyles';
import { MapTheme, InfoCardBtn, OrientationBtn} from './../shared/AppBarBtns';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '52px'
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menueBtn: {
  
  }
}));

export default function MobileMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { badgeContent, toggleMapTheme, toggleInfoCard, toggleOrientatoin} = props;
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
  
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  
  return (
    <div className={classes.container}>
      <Button className={classes.menueBtn}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {props.children}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
              <ClickAwayListener disableReactTree={true} onClickAway={handleClose}>
                <MenuList  autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem ><MapTheme badgeContent={badgeContent} toggleMapTheme={toggleMapTheme}/></MenuItem>
                    <MenuItem ><OrientationBtn toggleOrientatoin={toggleOrientatoin}/></MenuItem>
                    <MenuItem ><InfoCardBtn toggleInfoCard={toggleInfoCard}/></MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}


