import React, { useState, useEffect } from 'react';
 import './style/App.css'
import Layout from './container/Layout/index';
import { createMuiTheme, ThemeProvider}  from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { indigo, } from '@material-ui/core/colors';
import { localAdd, localupdate } from './utils/localstorage';
import { googleMapsApi } from './Api/helper';
import { defaultMapTheme} from './Api/constants';
import useCheckWindow  from './utils/useCheckWindow';
const theme = createMuiTheme({
  palette: {
   primary: indigo,
    secondary: {
      main:"#653BB5",
     dark: "#4C1CAA",
      label: "#acaeb0",
      background: "#e9e8ee",
      switch: "#00cae4",
    },
    text: {
      secondary: "#653BB5"
    }
  },
});


const theme2 = createMuiTheme({
  palette: {
    primary: {
      main: '#2e2e3d',
      secondary: "#7F5AC7",
    },
    secondary: {
      main: "#acaeb0",
      dark: "#5d6974",
      background: "#1A1A1B",
      label: "#acaeb0",
      switch: "#5d6974",
    },
   type: 'dark',
    text:{
      primary: '#ffffff',
      secondary: "#acaeb0",
    }
  },
});

const useStyles = makeStyles({
  base: {
    textAlign: "center",
    minHeight: "100vh",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    
  },
});

function App() {
  const [currentTheme, setCurrentTheme] = useState(defaultMapTheme);
  const themes = { 'light': theme, 'dark': theme2}
  const key = "mapTheme";
  const classes = useStyles();
  let isWindow = useCheckWindow();
  const toggleMapTheme = ()=> {
    const choices = { 'light': 'dark', 'dark': 'light' }
    const newTheme = choices[currentTheme]
    localAdd(key, newTheme)
    setCurrentTheme(newTheme);
    googleMapsApi.changeMapTheme(newTheme);
  }

  useEffect(()=> {
    const setStateCallBack = (key,value) => {
      setCurrentTheme(value);
      googleMapsApi.changeMapTheme(value);
    }
    localupdate(key, setStateCallBack);
  },[])
  
  return ( 
    <div className={`App ${classes.base}`}>
      {isWindow&&
        <ThemeProvider theme={themes[currentTheme]}>
          <Layout mapTheme={currentTheme} toggleMapTheme={toggleMapTheme} />
        </ThemeProvider >
      }
     
      
  
    
    </div>
     
  );
}

export default App;
