import React,  { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { usePickOrientation} from './../../hooks/index'

import SimpleBackdrop from './../../components/SimpleBackdrop/index'

const useStyles = makeStyles(theme => ({

  contentHorizontalContainer: {
    "flex-direction": "row-reverse",
     display: "flex", 
     width: "100%",
     "gap": "0",
     "row-gap": "0px",
     "justify-content":  "center",
  },

  mobileContainer: {
    "flex-direction":  "column-reverse",
    "display": "flex",
  },

  mapContentContainerMap: { 
   display: "flex",
  flexWrap: "wrap", 
   width: "100%", 
   "gap": "1px",

  },

   resultsContainerHorizontal:{
     height: "100vh", 
     "overflow": "scroll",
     "overflow-x": "hidden",

   },
  addBasis: {
    [theme.breakpoints.up('md')]: {
      "flex-basis": "100%",
    },
  },

}));

const Content = (props) => {
  const { results, googleMapDisplay, orientation, allPlacesTotal, isDataLoading} = props;
  const classes = useStyles();

  const verticleDisplay = { container: "", mapContainer: "", resultsContainer:""}
  const horizontalDisplay = {
    container: classes.contentHorizontalContainer,
    mapContainer: classes.mapContentContainerMap,
    resultsContainer: classes.resultsContainerHorizontal,
  }
  const mobileContainer = { container: classes.mobileContainer, mapContainer: "", resultsContainer: ""};

  const toDisplay = usePickOrientation(orientation, verticleDisplay, horizontalDisplay, mobileContainer);
   const frontPageMap = allPlacesTotal <= 0 ? classes.frontPageMap : ""
  const addBasis = allPlacesTotal > 0 ? classes.addBasis : ""
  const mapContainer = `${toDisplay.mapContainer} ${frontPageMap}`
  const resultsContainer = `${toDisplay.resultsContainer} ${addBasis}`

  return (
    <div className={toDisplay.container} id={"topMobile"}>
      {isDataLoading && <SimpleBackdrop />}
      <div className={resultsContainer}>
        {results}
      </div>
    
      <div className={mapContainer}>
        {googleMapDisplay}
      </div>
      {/* <CircleProgress /> */}
    </div>
  )


}

export default Content;