export class KingCounty {

  constructor(places, phoneData, type = null) {

    const finalUrl = getUrl();

    this.finalURL = finalUrl;
    this.placeId = places.placeId ;
    this.forJson = {};// or maybe a function
   // this.name = place.name;

    function getUrl(){
        let healthName = formatHealthName(places.name.toUpperCase());
        let address = formatAddress(places.address);
        let partialAddressQuery = '%20'
        let healthAPiQuery = formatUrlQuery(healthName, address, partialAddressQuery);
      return healthAPiQuery;
    }





    function formatAddress(initialAddress) {
      let addressArray = initialAddress.split(' ');
      addressArray = addressArray.slice(0, 2);
      let partialaddress = addressArray.join(' ');
      let address = encodeURIComponent(partialaddress).toUpperCase();
      return address;
    }

    function formatUrlQuery(healthName, address, partialAddressQuery) {
      let tempName = healthName;
      let index = tempName.lastIndexOf("%20");
      let firstIndex = tempName.indexOf("%20");
      let theArray = tempName.split('').slice(index + 3, tempName.length).join('');
      let first = tempName.split('').slice(0, firstIndex).join('');
      let t = `like%20'%25${theArray}'`;
      let firstHealth = `starts_with(name, '${first}')`;

      let url = `https://data.kingcounty.gov/resource/gkhn-e8mn.json?$where=(upper(address)%20like%20'%25${address}%25'%20AND%20(name%20${t}%20OR%20name%20=%20'${healthName}'%20OR%20${firstHealth}))${partialAddressQuery}&$order=inspection_date%20DESC`;
      // let urlOneRequst = `(upper(address)%20like%20'%25${address}%25'%20AND%20(name%20${t}%20OR%20name%20=%20'${healthName}'%20OR%20${firstHealth}))${partialAddressQuery}`
      return url;
    }
  }
}


export class NewYork {

  constructor(places,phoneData, type ="phoneURL") {

    const apiURL = "https://data.cityofnewyork.us/resource/43nn-pn8j.json?"
    const latLng = "https://data.cityofnewyork.us/resource/43nn-pn8j.json?$where=latitude > 0& < 1 & longitude > 0& < 1"
    const phoneURL = "https://data.cityofnewyork.us/resource/43nn-pn8j.json?phone="
    
    const apiToUse = { apiURL, latLng, phoneURL: getPhone}

    const constructedURL = apiToUse[type](phoneData)

   

    this.finalURL = constructedURL;
    this.placeId = phoneData.place_id;
    this.forJson = {};// or maybe a function

    function getPhone(phoneNumbers){
      let phoneNumbersFormated;
      if (phoneNumbers && phoneNumbers.hasOwnProperty("formatted_phone_number")) {
        phoneNumbersFormated = phoneNumbers.formatted_phone_number
          .split('')
          .filter(letter => letter !== '-' && letter !== ' ' && letter !== ')' && letter !== '(')
          .join('');
      }
       else if (phoneNumbers && phoneNumbers.hasOwnProperty("international_phone_number")) {
       // maybe check length?
       let indexOfOne = phoneNumbers.international_phone_number.indexOf("1");
       indexOfOne = indexOfOne <= 1 ? indexOfOne + 1 : 0
        phoneNumbersFormated = phoneNumbers.international_phone_number
          .split('')
          .slice(indexOfOne)
          .filter(letter => letter !== '-' && letter !== ' ' && letter !== '+' )
          .join('');
      }
      else {
        phoneNumbersFormated = null;
      }
 
      return phoneURL + phoneNumbersFormated;
    }
  }
}

class CookCounty {
  constructor(places, phoneData, type = "combine") {

   //debugger;
    const apiURL = "https://data.cityofnewyork.us/resource/43nn-pn8j.json?"
    const latLngLocation = " https://data.cityofchicago.org/resource/4ijn-s7e5.json?$where=within_circle(location, 47.59, -122.33, 1000)"
    const phoneURL = "https://data.cityofchicago.org/resource/4ijn-s7e5.json?phone="
   
   
    const apiToUse = { apiURL, latLngLocation, addressURL: getAddress, combine: formatUrlQuery }
//  debugger;
    let healthName = formatHealthName(places.name.toUpperCase());

    const constructedURL = apiToUse[type](healthName, places.address.toUpperCase());


    this.finalURL = constructedURL;
    this.placeId = phoneData.place_id;
    this.forJson = {};// or maybe a function
    function getAddress(){
      const address = places.address.toUpperCase();
      const name = places.name;
      return `https://data.cityofchicago.org/resource/4ijn-s7e5.json?address=${address}`
      //AND%20dba_name%20like%20'%25${name}%25`
    }

    function formatUrlQuery(healthName, address) {
      let tempName = healthName;
      let index = tempName.lastIndexOf("%20");
      let firstIndex = tempName.indexOf("%20");
      let theArray = tempName.split('').slice(index + 3, tempName.length).join('');
      let first = tempName.split('').slice(0, firstIndex).join('');
      let t = `like%20'%25${theArray}'`;
      let firstHealth = `starts_with(aka_name, '${first}')`;

      let url = `https://data.cityofchicago.org/resource/4ijn-s7e5.json?$where=(upper(address)%20like%20'%25${address}%25'%20AND%20(aka_name%20${t}%20OR%20aka_name%20=%20'${healthName}'%20OR%20${firstHealth}))%20&$order=inspection_date%20DESC`;
      // let urlOneRequst = `(upper(address)%20like%20'%25${address}%25'%20AND%20(name%20${t}%20OR%20name%20=%20'${healthName}'%20OR%20${firstHealth}))${partialAddressQuery}`
      return url;
    }

  }
}

class Austin {
  constructor(places, phoneData) {

   
    const apiURL = "https://data.austintexas.gov/resource/ecmv-9xxi.json?"


    let healthName = formatHealthName(places.name);
    const constructedURL = formatUrlQuery(healthName, places.lat, places.lng);
 
    this.finalURL = constructedURL;
    this.placeId = phoneData.place_id;
    this.forJson = {};// or maybe a function
//  debugger;

    function formatUrlQuery(healthName, lat,lng) {
      let tempName = healthName;
      let index = tempName.lastIndexOf("%20");
      let firstIndex = tempName.indexOf("%20");
      let theArray = tempName.split('').slice(index + 3, tempName.length).join('');
      let first = tempName.split('').slice(0, firstIndex).join('');
      let t = `like%20'%25${theArray}'`;
      let firstHealth = `starts_with(restaurant_name, '${first}')`;

      let url = `${apiURL}$where=within_circle(address,%20${lat},%20${lng},%20100)&$q=${first}%20&$order=inspection_date%20DESC`;
    //  https://data.austintexas.gov/resource/ecmv-9xxi.json?$where=within_circle(address,%2030.2664499,%20-97.7400715,%2050)&$q=Russian
      // let urlOneRequst = `(upper(address)%20like%20'%25${address}%25'%20AND%20(name%20${t}%20OR%20name%20=%20'${healthName}'%20OR%20${firstHealth}))${partialAddressQuery}`
      return url;
    }

  }
}


    function formatHealthName(name) {
      const regex = /%20and%20/gi;
      const regex3 = /'s/gi;
      const regex4 = /'/gi;

      name = modifyAccent(name);
      let healthName = fixedEncodeURIComponent(name.toUpperCase());
      healthName = healthName.replace(regex, '%20%26%20');
      healthName = healthName.replace(regex3, '')
      healthName = healthName.replace(regex4, '')

      return healthName;
    }


    function fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    }

    function modifyAccent(str) {
      const regex = RegExp('/[À-ÿ]/');
      const isAccent = regex.test(str);
      if (isAccent) {
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      }
      return str
    }

export const setHealthAPIURL = { "King County": KingCounty, 'New York': NewYork, "Cook County": CookCounty, "Travis County": Austin}