import React, { useState} from 'react';
import { WarnPopover } from '../PopOver';
import ListPopUpAllResults from '../ListPopUpAllResults';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMarkerInfo from './../../container/GoogleMap/useMarkerInfo'


const useStyles = makeStyles(theme => ({
  containerListButton: {
    [theme.breakpoints.up('lg')]: {
      marginTop: "10px"
    },
  },
}));


const ListButton = (props) => {
  const [listAnchor, setListAnchor] = useState({anchorEl:null})
  const { googleData, selectPlaceForMap, getDataByPlaceId, mapPlaceToDisplay, placeData, healthAPILocation}  = props

  let [listElements, setlistElements] = useState([]);
  const classes = useStyles();
  const placeCount = googleData.length;
  var popupInfo = useMarkerInfo(listElements, googleData, selectPlaceForMap, getDataByPlaceId, mapPlaceToDisplay, placeData, healthAPILocation, "list")


  const setIsShowList = (e) =>{
    const anchorEl =  e.currentTarget;
    setListAnchor({ anchorEl: anchorEl})
  }

  const lists = <ListPopUpAllResults googleData={googleData} setlistElements={setlistElements}/>
  const listpopOver = listAnchor.anchorEl == null ? null : <WarnPopover setanchorEl={(inp) => setIsShowList({ anchorEl: inp })} anchorEl={listAnchor.anchorEl}>{lists}</WarnPopover>

  return (
    <div className={classes.containerListButton}>
      <Button onClick={setIsShowList} variant="outlined" color="secondary" size="small">{`ALL ${placeCount}`}</Button  >
      {listpopOver}
      {popupInfo}
    </div>
  )
}

export default ListButton;