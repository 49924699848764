import React, { useState, useEffect, useRef} from 'react';
import { darkMap } from '../../Api/constants';
import { useIsDesktop} from './../../hooks/index'
import { googleMapsApi } from '../../Api/helper';
import StaticMap from './StaticMap';

const StaticDynamicInitialMap = (props) => {
  const [isStaticMap, setIsStaticMap] = useState(false);
  const [isStartDesktop, setIsStartDesktop] = useState(window.innerWidth > 900);
  const { mapTheme} = props;

  const { isDesktop, isDesktopLarger} = useIsDesktop();
  const mapUrl = constructMapStyle(darkMap, mapTheme, isDesktop, isDesktopLarger, isStartDesktop);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      setIsStartDesktop(window.innerWidth > 900)
      isFirstRun.current = false;
      return;
    }
    
  }, [isStartDesktop])

  useEffect(()=> {
    if (!isDesktop){
      setIsStaticMap(true)
    }
    else{
      const centeral = {lat:19.8097, lng:-98.5556}
      const closeZoom = 3;
      const farZoom = 2;
      const zoom = isDesktopLarger ? closeZoom : farZoom;
      googleMapsApi.initiateMap(centeral.lat, centeral.lng, zoom);
    } 
  }, [isDesktop, isDesktopLarger, setIsStaticMap])

  const toDisplay = isStaticMap && !isStartDesktop ? <StaticMap mapUrl={mapUrl}/> : null;

 return (
    <div>
      {toDisplay}
    </div>
 
  )

  function constructMapStyle(mapStyles, mapTheme, isDesktop, isDesktopLarger, isStartDesktop){
    const key = "AIzaSyB6S7PoVwH_OC5jcUpz0Q-Wu8gvpjg4lv4"
    const size = isDesktop ? "1900x1000" : "300x400";
    const zoom = "3";
    const styleOptions = {feature: "featureType", element: "elementType", color: "stylers"}
    let styles = "";

    if (mapTheme === 'dark'){
      mapStyles.forEach(eleStyle => {
        if (Object.keys(eleStyle).length > 2) {
          const feature = `${eleStyle[styleOptions["feature"]]}`
          const element = `${eleStyle[styleOptions["element"]]}`
          let color = `${eleStyle[styleOptions["color"]][0]["color"]}`
          color = color.replace('#', '0x');
          let temp = `&style=feature:${feature}%7Celement:${element}%7Ccolor:${color}`;
          styles += temp
        }
      })
    }

    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=${zoom}&size=${size}&maptype=roadmap${styles}&scale=2&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=${key}`
    return mapUrl;
  }
}

export default StaticDynamicInitialMap;