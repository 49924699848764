import halfStar from './../../../assets/google/half.png';
import wholeStar from './../../../assets/google/full.png';
import emptyStar from './../../../assets/google/empty.png';

export function getStarsToDisplay(rating:number): Array<string> {
  const multiplier:number = 10;
  const originalRating: number = rating * multiplier;
  const truncRating: number  = Math.trunc(rating);
  const partialRating: number  = originalRating - truncRating * multiplier;
  const totalStars: number  = 5;
  const starsToDisplay: Array<string> = [];

  for (let i = 0; i < totalStars; i++) {
    if (i < truncRating) {
      starsToDisplay.push(wholeStar);
    }
    else if (i === truncRating) {
      const partialStar = partialRatingStar(partialRating);
      starsToDisplay.push(partialStar);
    }
    else {
      starsToDisplay.push(emptyStar);
    }
  }
  return starsToDisplay;
}
export function partialRatingStar(partialRating:number) :string {
  let star :string;
  switch (true) {
    case (partialRating >= 8):
      star = wholeStar;
      break;
    case (partialRating >= 3):
      star = halfStar;
      break;
    case (partialRating >= 0):
      star = emptyStar;
      break;
    default:
      star = emptyStar;
      break;
  }
  return star;
}