
import React from 'react';
import  makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';                 
import RatingImages from './RatingImages';
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    cursor: "default",
    padding: "10px",
    paddingBottom: "0px",
   
  },
  content: {

    padding: "5px",
    "&:last-child": {
      paddingBottom: "0px",
    },
   
  },
  media: {
    height: 140,
  },

  container:{
  marginTop: "15px",
    marginBottom: "15px",
  },

  date: {
    marginLeft: "10px",
  }
});

export default function ReviewDisplay(props) {
  const classes = useStyles();
  const { reviews } = props;
 // debugger;
   const displayReviews = reviews.map((ele,i)=> {
     return(
       <div key={ele.id} className={classes.container}>
         <RatingImages rating={ele.rating} /><span className={classes.date}>{ele.time_created}</span>
         <Typography variant="body2" color="textPrimary" component="p">
           {ele.text}
          </Typography>
         <a href={ele.url} target={"_blank"}>full review</a>
       </div>
     
     )
  })  
  return (
     <React.Fragment>
      {displayReviews}
     </React.Fragment>
  );
}
