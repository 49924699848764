import React from 'react';

const RatingText = (props) => {
  const { rating } = props
  const toShow:string = rating ? rating : "Not Rated"
  return (
    <div>{toShow}</div>
  )
}

export default RatingText;