import React, { useState, useEffect } from 'react'

const useCheckWindow = () => {
  const [show, setShow] = useState(false)

 useEffect(() => {
    if (!window?.isSet ) {
      let timeout;
      function getIsSet(){
         if (!window?.isSet){
       //    console.log("!window?.isSet", !window?.isSet);
           timeout  = setTimeout(() => {
             getIsSet()
           }, 400)
        }
        else{
           setShow(true)
           return () => clearTimeout(timeout);          
        }
         }
      getIsSet();
    }
    else{
      setShow(true)
    }
  }, [])

  return show
}

export default useCheckWindow
