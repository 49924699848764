import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { WarnPopover} from './../../PopOver/index'
import InspectionsTable from './../../InspectionsTable/index'
import Button from '@material-ui/core/Button';
import RatingText from './RatingText';
import {getRatingImage} from './pictures';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 40,
    width: 50,
  },
});
const HealthInfo = (props) => {
  const classes = useStyles();
  const  [anchorEl, setanchorEl] = useState(null);
  const { healthPlace, healthAPILocation} = props;
  const { healthImage, showRating } = getRatingImage(healthPlace, healthAPILocation);


  const handlePopTable = (e) => {
    setanchorEl(e.currentTarget);
  }
  const isImage = !!healthImage;


  return (
      <div className='health-container'>
        <div>Health Department Rating</div>
        <div className="health-data-container">
        {isImage ? <CardMedia
          className={classes.media}
          image={healthImage || "na"}
          title={healthPlace.name}
        /> :null} 
          <CardActions>
          {isImage ? null : <RatingText rating={showRating} />}
          <Button onClick={handlePopTable} size="small" color="secondary"  variant="outlined">
                Inspections
            </Button>         
              <WarnPopover setanchorEl={setanchorEl} anchorEl={anchorEl}><InspectionsTable tableData={healthPlace.inspectionHistory}/></WarnPopover>
          </CardActions>
          </div>
    </div>
  )
}
export default HealthInfo;