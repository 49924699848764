import React from 'react';
import { makeStyles } from '@material-ui/core';
import { usePickOrientation } from './../../hooks/index'

const useStyles = makeStyles(theme => ({
  staticMapContainer: {
    "max-width": "100%",
    "overflow-x": "hidden",
  },
}));

const StaticMap = (props) => {
  const { mapUrl} = props
  const classes = useStyles();
  return (
    <div className={classes.staticMapContainer}>
      <img src={mapUrl} alt="" />
    </div>
  )
}

export default StaticMap;