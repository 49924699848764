import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme=>({
  root:{
    maxWidth: 630,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      maxHeight: 430,
     maxWidth: 370,
    },
  },
  table: {
   width: "100%",
    "min-width": 0,
    [theme.breakpoints.down('md')]: {
      height: 230,
    },
  },
  cell: {
    padding: "2px",
    [theme.breakpoints.down('md')]: {
      fontSize: "0.575rem",
    },
  }
}));

export default function InspectionsTable(props) {
  const classes = useStyles();
  const {tableData} = props;

 const rows = createData(tableData);
  return (

    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead >
          <TableRow >
            <TableCell padding="none" className={classes.cell} size={'small'}>Business Name</TableCell>
            <TableCell padding="none" className={classes.cell} size={'small'} align="center" >Inspection Date</TableCell>
            <TableCell padding="none" className={classes.cell} size={'small'} align="center" >Inspection Result&nbsp;</TableCell>
            <TableCell padding="none" className={classes.cell} size={'small'} align="center" >Violation Points&nbsp;</TableCell>
            <TableCell padding="none" className={classes.cell} size={'small'} align="center" >Violation Destription</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

 function createData(tableData) {
  const rows = tableData.map((ele,i) => {
    const classes = useStyles();
    const { name,inspection_date, violation_points,
      inspection_result, 
      violation_description } = ele
    const violationDestription = violation_description || 'none available'
    const inspectionDateFormated = getFormattedDate(inspection_date);
  return (
      <TableRow key={i}>
      <TableCell padding="none" className={classes.cell} size={'small'} component="th" scope="row">
          {name}
        </TableCell>
      <TableCell padding="none" className={classes.cell} size={'small'} align="center">{inspectionDateFormated}</TableCell>
      <TableCell padding="none" className={classes.cell} size={'small'} align="center">{inspection_result}</TableCell>
      <TableCell padding="none" className={classes.cell} size={'small'} align="center">{violation_points}</TableCell>
      <TableCell padding="none" className={classes.cell} size={'small'} align="center">{violationDestription}</TableCell>
      </TableRow >
    )
  })
  return rows;

   function getFormattedDate(date) {
     date = new Date(date);
     let year = date.getFullYear();
     let month = (1 + date.getMonth()).toString().padStart(2, '0');
     let day = date.getDate().toString().padStart(2, '0');
     return month + '/' + day + '/' + year;
   }
}

