import React, { useState, useEffect,useCallback } from 'react';
import{ WarnPopover } from '../../components/PopOver/index'
import DisplayCard from './../../components/DisplayCard/DisplayCard'
//import { useIsShowHealth} from './../../hooks/index';
import CircularProgress from '@material-ui/core/CircularProgress';

const useMarkerInfo = (mapMarkers, googleData, selectPlaceForMap, getDataByPlaceId, mapPlaceToDisplay, placeData, healthAPILocation, typeOfElement="map",) => {
  const [markerInfo, setMarkerInfo] = useState({ anchorEl: null, found:"",
    infoDisplay: "", currentId:""});
  // const isShowHealth = useIsShowHealth(placeData);

  const getDisplayCard = useCallback((inp) => {
    const isMapPlaceToDisplay = Object.keys(mapPlaceToDisplay).length > 0 && mapPlaceToDisplay.constructor === Object ? true : false;
    if (isMapPlaceToDisplay) {
      return <DisplayCard isDesktop={true} healthAPILocation={healthAPILocation} placeData={placeData} changeMapIcon={() => null} googleYelpHealthData={mapPlaceToDisplay} />
    }
  }, [healthAPILocation, placeData, mapPlaceToDisplay, ]);

  useEffect(() => {
    const display = <CircularProgress size={20} color="inherit" />
    if (markerInfo.infoDisplay === ""){
      setMarkerInfo(prev => {
        return { found: prev.found, anchorEl: prev.anchorEl, infoDisplay: display, currentId: prev.placeId }
      })
    }
  }, [markerInfo.infoDisplay])

  useEffect(()=> {
    mapMarkers.forEach((ele, i) => {
      if (mapMarkers.length > 0 && typeOfElement === "map"){
            ele.addListener('click', function (e) {
              const found = googleData.find(elementToFind => ele.title === elementToFind.name && ele.placeId === elementToFind.placeId);
              const anchorEl = e.domEvent.currentTarget;
              setPlaceInfo(found, anchorEl);
           })
        }
      else if (typeOfElement === "list" && ele != null){      
        ele.addEventListener('click', function (e) {
          const clickedPlaceId = ele.attributes.getNamedItem("placeid")
          const found = googleData.find(elementToFind => ele.innerText === elementToFind.name && clickedPlaceId.nodeValue === elementToFind.placeId);
          const anchorEl = e.currentTarget;
          setPlaceInfo(found, anchorEl);
        })
      }

      function setPlaceInfo( found, anchorEl) {       
        selectPlaceForMap(found);    
        setMarkerInfo(prev => {
          if (prev.currentId === found.placeId){
            const card = prev.infoDisplay ? prev.infoDisplay : getDisplayCard();
            return { anchorEl: anchorEl, found: found.placeId, infoDisplay: card, currentId: found.placeId };
         }
          else  if (prev.found !== found.placeId) {
            return { found: found.placeId, anchorEl: anchorEl, infoDisplay: "", currentId: prev.currentId };
          }
          else {
            return { anchorEl: anchorEl, found: prev.found, infoDisplay: prev.infoDisplay, currentId: prev.currentId };
          }
        });
      }
    })
  }, [mapMarkers, typeOfElement, googleData, selectPlaceForMap, getDataByPlaceId, getDisplayCard])

  
  useEffect(() => {
    const displaycard = getDisplayCard();
    if (displaycard  ) {
      setMarkerInfo(prev => {;
        return { found: prev.found, anchorEl: prev.anchorEl, infoDisplay: displaycard, currentId: mapPlaceToDisplay.googlePlace.placeId}
      })
    }
  }, [mapPlaceToDisplay, placeData, getDisplayCard])

  const changeAnchor = useCallback((inp) => {
    setMarkerInfo(prev => {
      return { found: prev.found, anchorEl: inp, infoDisplay: prev.infoDisplay }
    })
  }, [setMarkerInfo]);
  return markerInfo.anchorEl == null ? null : <WarnPopover setanchorEl={(inp) => changeAnchor(inp)} anchorEl={markerInfo.anchorEl} >{markerInfo.infoDisplay}</WarnPopover>
}

export default useMarkerInfo;